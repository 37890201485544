import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
//import { SharedServiceService } from '../service/shared-service.service';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AppConfigService } from '../services/app-config.service';
import { AdpaIntegrationService } from '../services/adpa-integration.service';
import { addEventHandler } from '@microsoft/applicationinsights-core-js';
//import { AppConfigService } from '../services/app-config.service';


// import { AppConfigService } from './app-config.servic';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    token: string = '';
    configData: any = null;
    adpaToken: string = '';
    
    //public _config: any;
    public environmentDetails: any;
    constructor(private authService: MsalService, private broadcastService: MsalBroadcastService,
        config: AppConfigService, public adpaService: AdpaIntegrationService) {
        this.configData= config.getConfig();
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      
        
        if (req.url.includes('/logout')) {
            return next.handle(req);
        }
        

      
 
      //  this.environmentDetails = this.appConfig.environmentSettings;

      //  if (this.environmentDetails.MSALenabled == true) {
  
        const msal = this.configData.msal;
        const framework = msal.auth.framework;
        const scopeValue = framework.protectedResourceMap[0][1];
      
  
            console.log('****************** account active ... get token ******************');
            var tokenRequest = {
                scopes: scopeValue
            };

            let token: string;
            return from(
                this.authService.instance.acquireTokenSilent(tokenRequest)
                    .then((response) => {
                        //   token = response.tokenType === ServerHashParamKeys.ID_TOKEN ? response.idToken.rawIdToken : response.accessToken;
                        token = response.accessToken;
                        const authHeader = `Bearer ${token}`;
                        this.token = authHeader
                        console.log("Acquired token silent sucessfully");
                        return req.clone({
                            setHeaders: {
                                Authorization: authHeader,
                                
                            }
                        });
                     
                    }, error => {
                        console.log("Acquire token silentily interceptor failed. Trying again", req);
                        return this.authService.instance.acquireTokenSilent(tokenRequest)
                            .then((response) => {
                                //  token = response.tokenType === ServerHashParamKeys.ID_TOKEN ? response.idToken.rawIdToken : response.accessToken;
                                token = response.accessToken;
                                const authHeader = `Bearer ${token}`;
                                return req.clone({
                                    setHeaders: {
                                        Authorization: authHeader,
                                    }
                                })
                            }, error => {
                                console.log("Acquire token silentily interceptor failed 2. Trying again", req);
                                return this.authService.instance.acquireTokenSilent(tokenRequest)
                                    .then((response) => {
                                        //   token = response.tokenType === ServerHashParamKeys.ID_TOKEN ? response.idToken.rawIdToken : response.accessToken;
                                        token = response.accessToken;
                                        const authHeader = `Bearer ${token}`;
                                        return req.clone({
                                            setHeaders: {
                                                Authorization: authHeader,
                                            }
                                        })
                                    });
                            });
                    })
            )
                .pipe(
                    mergeMap(nextReq => next.handle(nextReq)),
                    tap(
                        () => { },
                        err => {
                            if (err instanceof HttpErrorResponse && err.status === 401) {
                                //  this.auth.clearCacheForScope(token);
                                // this.broadcastService.("msal:notAuthorized", err.message);
                                console.log("Logged: Interceptor : err");
                            }
                        }
                    )
                );

        // } else {
        //     // user is not logged in, you will need to log them in to acquire a token
        //     //***** LOCAL DEBUG ******          
        //     console.log("Logged: local Token");
        //     const userToken = 'Bearer abcd1234';
        //     const authHeaderRequest = req.clone({
        //         // setHeaders: {
        //         //     Authorization: userToken
        //         // }
        //     })
        //     return next.handle(authHeaderRequest);

        // }


        //return next.handle(req);

        // throw new Error('Method not implemented.');
    

   
    }

}