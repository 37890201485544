import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rebar-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.css']
})
export class PopUpComponent {

}
