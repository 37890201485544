import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RebarAuthService } from './core/rebarauth/rebar.auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private http: HttpClient, private rebar: RebarAuthService) { }
  CheckAccess(entId) {
    let params = {
      entId: entId
    }


  //   return this.http.get(this.rebar.configData.configURL + 'MDDashboardService-service/MDDashboardService?entId=' + entId,
  //  {responseType: 'text'});
  //  return this.http.get(this.rebar.configData.configURL + 'MDDashboardService-service/MDDashboardService',
  //  {responseType: 'text', params: params, headers:this.rebar.setHttpHeader()});
      // return this.http.get(this.rebar.configData.configURL + 'MDDashboardService-service/MDDashboardService',
      //  {responseType: 'text', params: params});
      return this.http.get(this.rebar.configData.configURL + 'MDDashboardService-service/MDDashboardService/getRole',
      {responseType: 'text',});

}
}
