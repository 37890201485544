import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';
import { RebarAuthService } from '../rebarauth/rebar.auth.service';
@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
   appInsights: ApplicationInsights;
  constructor(private auth: RebarAuthService) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.auth.configData.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
        autoTrackPageVisitTime: true,
        //disableTelemetry: true,
        //disableCookiesUsage: true,
       

      }
    });
    this.appInsights.loadAppInsights();
   }
   clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();

   }
   setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }
  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }
}
