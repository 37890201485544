import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RebarAuthService } from '../../core/rebarauth/rebar.auth.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient, private rebar: RebarAuthService) { }

   
  PostFeedbackData(entId: any, data: any) {
    // return this.http.post(this.rebar.configData.configURL +
    //   'FeedbackService-service/FeedbackService?entId=' + entId, data, {headers: this.rebar.setHttpHeader()});
    return this.http.post(this.rebar.configData.configURL +
      'FeedbackService-service/FeedbackService?entId=' + entId, data);
  }
}
