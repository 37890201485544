<h5 class="mb-2">Feedback</h5>
<hr>
<div class="row">
    <div class="col-md-12">
        <div class="alert alert-success alert-dismissible" role="alert" *ngIf="successfulSave">
            <button type="button" class="close alert-success" data-dismiss="alert" aria-label="Close" (click) = "onSuccessAlertClose()">
              <span aria-hidden="true">&times;</span>
            </button>
            <span>{{successMsg}}</span>
          </div>
    </div>
</div>
<form [formGroup] ="Form" role="form" autocomplete="off" (ngSubmit)="onSubmit()">
    <div class="form-group row">
        <label class="col-lg-5">Feedback Category</label>
        <div class="col-lg-7">
            <ng-multiselect-dropdown [placeholder]="'Select'" [settings]="fbCatDropdownSettings" [data]="fbCategory"
                #fbCatList formControlName = "fbCategory">
            </ng-multiselect-dropdown>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-lg-5">State The Question</label>
        <div class="col-lg-7">
            <input class="form-control" formControlName="question" name="question" type="text" maxlength="100">
        </div>
    </div>
    <div class="form-group row">
        <label class="col-lg-5">Feedback Details</label>
        <div class="col-lg-7">
            <textarea class="form-control" formControlName="detail" name="fbDetail" placeholder="Enter Comment" style="height: 130px;"></textarea>
        </div>
    </div>

    <div class=" form-group row" style="float: right; margin-right: 1px;">

        <input type="submit" class="btn btn-primary" style="margin-right: 5px;" value="Submit">
        <input type="reset" class=" btn btn-secondary" mat-dialog-close value="Cancel">

    </div>
</form>

<!-- /form user info -->