import { Component, OnInit, SimpleChanges, OnChanges, Inject, Renderer2, ViewChild } from '@angular/core';
import { RebarAuthService } from './core/rebarauth/rebar.auth.service';
import { AppService } from './app.service';
// import { Router, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, RoutesRecognized } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import {
    Router,
    Event,
    NavigationStart, RoutesRecognized, RouteConfigLoadStart,
    RouteConfigLoadEnd, NavigationEnd, NavigationCancel, NavigationError
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AppInsightsService } from './core/services/app-insights.service';
import { datadogRum } from '@datadog/browser-rum';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { Observable, Subject, of } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AdpaIntegrationService } from '../app/core/services/adpa-integration.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

declare let IM_CLD_CentRum: any;
@Component({
    selector: 'rebar-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnChanges {
    res = 'empty';
    isVisible: boolean;
    flag: string;
    userDetails: {};
    currentUrl: string;
    previousUrl: string;
    isValid: boolean;
    routingUrl: string;
    isUnauthorized: boolean;

    clientId: string;
    applicationId: string;
    service: string;
    env: string;
    site: string;
    allowedtracingoriginsdns : string;
    idleState;
    timedOut: boolean;
    lastPing?: Date = null;
    public modalRef: BsModalRef;
    private readonly destroying$ = new Subject<void>();
    public authObserver$: Observable<boolean>;

    @ViewChild('childModal', { static: false }) childModal: ModalDirective;

    constructor(private auth: RebarAuthService, private app: AppService,
                private router: Router,
                private AppInsights: AppInsightsService,
                private msalBroadcastService: MsalBroadcastService,
                private idle: Idle, private keepalive: Keepalive
               ) {
                    this.isVisible = false;
                    this.isValid = false;
                    console.log("test", this.auth.configData);
                    this.isUnauthorized = false;
                    this.clientId = this.auth.configData.DatadogRUM?this.auth.configData.DatadogRUM.clientToken:null;
                    this.applicationId = this.auth.configData.DatadogRUM?this.auth.configData.DatadogRUM.applicationId:null;
                    this.env = this.auth.configData.DatadogRUM?this.auth.configData.DatadogRUM.env:null;
                    this.service = this.auth.configData.DatadogRUM?this.auth.configData.DatadogRUM.service:null;
                    this.site = this.auth.configData.DatadogRUM?this.auth.configData.DatadogRUM.site:null;
                    this.allowedtracingoriginsdns = this.auth.configData.DatadogRUM?this.auth.configData.DatadogRUM.allowedtracingoriginsdns:null;
                    idle.setIdle(900);
                    idle.setTimeout(900);
                    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
                    idle.onIdleEnd.subscribe(() => { 
                      this.idleState = 'No longer idle.'
                      console.log(this.idleState);
                      this.reset();
                    });
                    
                    idle.onTimeout.subscribe(() => {
                      this.idleState = 'Timed out!';
                      this.timedOut = true;
                      console.log(this.idleState);
                      this.router.navigate(['/']);
                    });
                    
                    idle.onIdleStart.subscribe(() => {
                        this.idleState = 'You\'ve gone idle!'
                        console.log(this.idleState);
                        this.childModal.show();
                    });
                    
                    idle.onTimeoutWarning.subscribe((countdown) => {
                      this.idleState = 'You will time out in ' + countdown + ' seconds!'
                      console.log(this.idleState);
                    });
                    keepalive.interval(15);

                    keepalive.onPing.subscribe(() => this.lastPing = new Date());
                    if (this.auth.getUser()) {
                        idle.watch()
                        this.timedOut = false;
                    } else {
                        idle.stop();
                    }
                    
                    // this.reset(); 
                   
               }
    ngOnInit() {
       // show app content only if user is authenticated or app is running in
       // mock eso configuration using npm run start:local command
       
       this.toPreventURLFromTampering();
      
        //this.adpaService.getProfileImgToken();
        /* istanbul ignore next */
        // if (!this.auth.authenticationEnabled()) {
        //     this.checkAccess();
        // }
        
     if (this.auth.authenticationEnabled()) {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          ),
          takeUntil(this.destroying$)
        )
        .subscribe(() => {
          this.auth.checkAndSetActiveAccount();
          if (this.auth.authenticationEnabled() && !this.auth.isUserAuthenticated()) {
            this.login();
          }
          else
           {
                //  console.log("Inside auth enabled user authenticated...");

                /* istanbul ignore next */
                    if (this.auth.authenticationEnabled() && this.auth.isUserAuthenticated()) {
                       this.callAppInsightCode();
                        //this.callpiwikCode();
                        //this.callDatadogRum();
                        this.checkAccess(); 
                    }
           }
           this.callDatadogRum();
        });
      this.authObserver$ = this.msalBroadcastService.inProgress$.pipe(
        map((status) => {
          return status === InteractionStatus.None;
        }),
        takeUntil(this.destroying$)
      );
    } else {
      this.authObserver$ = of(true);
    }

        // this.auth.checkAndSetActiveAccount()
        // if (this.auth.authenticationEnabled() && !this.auth.isUserAuthenticated()) {
          
        // this.login()
            
        // }
        // else
        //    {
        //      //   console.log("Inside auth enabled user authenticated...");

        //         /* istanbul ignore next */
        //             if (this.auth.authenticationEnabled() && this.auth.isUserAuthenticated()) {
        //                this.callAppInsightCode();
        //                 //this.callpiwikCode();
        //                 this.callDatadogRum();
        //                 this.checkAccess();
        //             }
        //    }
    // this.auth.authObserver$.subscribe(authStatus => {
    // if (authStatus) {
    //     console.log(`user is logged in ${authStatus}`);
    //     console.log(`Username ****** ${this.auth.getUser()}`);
    // }
    // this.reauthObserver$.subscribe(authStatus => {
    //     if (authStatus) {
    //         console.log("auth observer",this.authObserver$)
    //         console.log(`user is logged in ${authStatus}`);
    //         // console.log(`Username ****** ${this.rebarAuthService.getUser()}`);
    //     }
    //});
}
login(){
  this.auth.login();
}
reset() {
  this.idle.watch();
  this.idleState = 'Started.';
  this.timedOut = false;
}
hideChildModal(): void {
  this.childModal.hide();
}

stay() {
  this.childModal.hide();
  this.reset();
}
logout(){
  this.auth.logout();
}
    callDatadogRum() {
        // datadogRum.init({
        //     applicationId: this.applicationId,
        //     clientToken: this.clientId,
        //     site: this.site,
        //     service: this.service,
        //     env: this.env,
        //     version: '1.0.0',
        //     sampleRate: 100,
        //     trackInteractions: true,
        //     useSecureSessionCookie: true
        // })
        //let IM_CLD_CentRum: any;
        const userTokenClaims = JSON.stringify(this.auth.getUserClaims());
        const parsedClaims = JSON.parse(userTokenClaims);
        console.log(parsedClaims);
        const payload = {
            businessOrg: parsedClaims.businessOrg,
            countryDescription: parsedClaims.countryDescription,
            countryCode:parsedClaims.countryCode,
            jobTitle:parsedClaims.jobTitle,
            jobFamily:parsedClaims.jobFamily,
            location: parsedClaims.location,
            locationCode: parsedClaims.locationCode,
            jobFamilyDescription: parsedClaims.jobFamilyDescription
        }
        //Set Item to Session Storage
        sessionStorage.setItem('ddrumsdk.tokenclaims', JSON.stringify(payload));
        IM_CLD_CentRum(this).log({
          applicationId: this.applicationId,
          clientToken: this.clientId,
          service: this.service,
          env: this.env,
          sampleRate: 100,
          trackInteractions: true,
          allowedTracingOrigins: this.allowedtracingoriginsdns,
          useSecureSessionCookie: true,
        });
        
    }

    callAppInsightCode() {
       // this.msal.user = this.msal.getUser();
       //    const username = this.msal.instance.getAccount().userName.substring(0, this.msal.getAccount().userName.lastIndexOf("@"))
    const username = this.auth.getUser().substring(0, this.auth.getUser().lastIndexOf("@"))
       // const username = this.msal.user.displayableId.substring(0, this.msal.user.displayableId.indexOf('@'));
        this.AppInsights.setUserId(username);

    }
     /* istanbul ignore next */
    // callpiwikCode() {
    //     // this.msal.user = this.msal.getUser();
    //     const csusername = this.msal.getAccount().userName.substring(0, this.msal.getAccount().userName.lastIndexOf("@"))

    //     // const csusername = this.msal.user.displayableId.substring(0, this.msal.user.displayableId.indexOf('@'));
    //     // console.log('Username: ' + csusername);
    //     window['_paq'].push(['setCustomData', { 'csusername': csusername }]);
    //     window['_paq'].push(['setCustomUrl', location.href.toLowerCase()]);
    //     window['_paq'].push(['trackPageView']);
    //     window['_paq'].push(['enableLinkTracking']);
    //     const u = this.auth.configData.PIWIK_URL;
    //     // console.log('PIWIK url: ' + u + '  PIWIK Site ID: ' + this.auth.configData.PIWIKSiteID);
    //     window['_paq'].push(['setTrackerUrl', u + 'piwik.php']);
    //     window['_paq'].push(['setSiteId',  this.auth.configData.PIWIKSiteID]);
    //     const g = this._renderer2.createElement('script');
    //     g.type = `text/javascript`;
    //     g.async = true;
    //     g.defer = true;
    //     g.src = u + 'piwik.js';
    //     this._renderer2.appendChild(this._document.body, g);
    // }


   /* istanbul ignore next */
    ngOnChanges(changes: SimpleChanges): void {
        // this.initTimer();
       this.toPreventURLFromTampering();
    }
    private toPreventURLFromTampering() {
       // this.userDetails = this.msal.user;
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart || event instanceof RouteConfigLoadStart || event instanceof RoutesRecognized
                || event instanceof RouteConfigLoadEnd || event instanceof NavigationEnd) {

                this.previousUrl = this.currentUrl;
                this.currentUrl = event['url'];
               // console.log('current url: ' + this.currentUrl);
              //  console.log('previous url: ' + this.previousUrl);

                if (this.currentUrl && (this.previousUrl !== this.currentUrl)) {
                   // console.log('Inside IF condition this.previousUrl !== this.currentUrl ');
                    // let pageRoute = this.getPageRouteCorrespondingToURL(event['url']);
                    this.pageRoute(event['url']);
                  //  console.log(event['url']);

                }
            }
        });
    }


pageRoute(pageRoute: any) {
    if (!((pageRoute === '/dashboard') || (pageRoute === '/basesheet') || (pageRoute === '/fuut') || (pageRoute === '/error') ||
     (pageRoute === '/email-reminder') || (pageRoute === '/') || (pageRoute === '/monitor') || (pageRoute === '/imagedownload') ||
     (pageRoute === '/data-upload') || (pageRoute === '/not-found') || (pageRoute === '/newdashboard') || (pageRoute === '/customdashboard')||(pageRoute === '/log-dashboard')
     || (pageRoute === '/mobiledashboard') || (pageRoute === '/customdashboard-v2') || (pageRoute === '/email-reminder') ||
     (pageRoute === '/dashboard?dashboard=MD_AD_Ratio_Dashboard&sheet=SH_MDAD') ||
     (pageRoute === '/dashboard?dashboard=MD_Dashboard&sheet=SH_AS') || (pageRoute === '/dashboard?dashboard=MD_Summary&sheet='))) {
      //  console.log('Inside Page route');
      //  console.log('pageRoute: ' + pageRoute);
      //  this.router.navigate(['/error']);
    }
}

    checkAccess() {
        console.log(this.auth.getUser());
        //this.msal.user = this.msal.getUser();
        //this.app.CheckAccess(this.msal.instance.getAccount().userName).subscribe(result => {
         this.app.CheckAccess(this.auth.getUser()).subscribe(result => {
            this.res = result;
            this.flag = JSON.parse(result);
          

                /* istanbul ignore next */
            if (this.flag === 'ADMIN') {
                this.isVisible = true;
                // this.router.navigate([pageroute]);

            } else if (this.flag === 'Data Provider') {
                this.isVisible = true;
                this.router.navigate(['/fuut']);
            } else if (this.flag === 'Application Monitor') {
                this.isVisible = true;
                this.router.navigate(['/monitor']);
            } else if (this.flag === 'Operations') {
                this.isVisible = true;
                this.router.navigate(['/log-dashboard']);
            } else if (this.flag === 'Media Provider') {
                this.isVisible = true;
                this.router.navigate(['/imagedownload']);
            } else {
                this.isUnauthorized = true;
                this.router.navigate(['/not-found']);
             }

             },
             /* istanbul ignore next */
             (err) => {
                this.isUnauthorized = true;
              //  console.log('Error Occurred while check access api: ' + err);
                // this.router.navigate(['/error']);
             });


    }



}
