import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackComponent } from './feedback.component'
import { ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [FeedbackComponent],
  imports: [
    CommonModule, ReactiveFormsModule, NgMultiSelectDropDownModule
  ]
})
export class FeedbackModule { }
