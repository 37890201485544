import { Component, OnInit, AfterViewInit} from '@angular/core';
import { HeaderService } from './header.service';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { ErrorComponent } from '../error/error.component';
import { PopUpComponent } from '../../feature/pop-up/pop-up.component';
import { AppInsightsService } from '../../core/services/app-insights.service';
import { FeedbackComponent } from '../../feature/feedback/feedback.component';
import { RebarAuthService } from '../../core/rebarauth/rebar.auth.service';

@Component({
  selector: 'rebar-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  flag;
  userImage: string;
  userName: string;
  userRole: string;
  pageUrl: string;
  environment: string;
  igRole:string;
  constructor(private headerService: HeaderService, private msal: MsalService,
              private router: Router,
              private dialog: MatDialog,
              private AppInsights: AppInsightsService,
              private rebar: RebarAuthService
    ) {
    this.pageUrl = window.location.pathname;
  }

  ngOnInit() {
   this.GetEntId();

  }
  GetEntId() {
    //this.msal.user = this.msal.getUser();
    // this.GetDetails(this.msal.getAccount().userName);
    this.GetDetails(this.rebar.getUser());
  }
  GetDetails(entId) {
    this.headerService.GetUserDetails(entId).subscribe(result => { this.flag = result;
                                                                   this.userName = this.flag.User_Name;
                                                                   this.userImage = 'https://thumbnail.accenture.com/' +
                                                                   this.flag.People_Key + '.jpg';
                                                                   this.userRole = this.flag.Role;
                                                                   this.igRole = this.flag.Role_Type;
         });


}
logout() {
  this.AppInsights.clearUserId();
  this.msal.logout();
}
setDefaultPic() {
  this.userImage = '../../../assets/img/default.jpg';
}

/* istanbul ignore next */
openPopup() {
  this.dialog.open(PopUpComponent);
}
openFeedbackPopup(){
  this.dialog.open(FeedbackComponent,{ disableClose: true });
}
}
