import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { REBAR_AUTH_GUARD } from './core/rebarauth/rebar.auth.module';
import { ErrorComponent } from './shared/error/error.component';


const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'login-failed',
        redirectTo: '/',
        pathMatch: 'full',
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./feature/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [REBAR_AUTH_GUARD]
    },
    
       {
        path: 'custom-input',
        loadChildren: () => import('./feature/custom-input/custom-input.module').then(m => m.CustomInputModule),
        canActivate: [REBAR_AUTH_GUARD]
    },
    {
        path: 'action-plan',
        loadChildren: () => import('./feature/action-plan/action-plan.module').then(m => m.ActionPlanModule),
        canActivate: [REBAR_AUTH_GUARD]
    },
    {
        path: 'fuut',
        loadChildren: () => import('./feature/fuut/fuut.module').then(m => m.FuutModule),
        canActivate: [REBAR_AUTH_GUARD]
    },
    {
        path: 'basesheet',
        loadChildren: () => import('./feature/basesheet/basesheet.module').then(m => m.BasesheetModule),
        canActivate: [REBAR_AUTH_GUARD]
    },
    {
        path: 'monitor',
        loadChildren: () => import('./feature/monitor/monitor.module').then(m => m.MonitorModule),
        canActivate: [REBAR_AUTH_GUARD]
    },
    {
        path: 'imagedownload',
        loadChildren: () => import('./feature/imagecontroller/imagecontroller.module').then(m => m.ImagecontrollerModule),
        canActivate: [REBAR_AUTH_GUARD]
    },
    {
        path: 'data-upload',
        loadChildren: () => import('./feature/data-upload/data-upload.module').then(m => m.DataUploadModule),
        canActivate: [REBAR_AUTH_GUARD]
    },
    {
        path: 'newdashboard',
        loadChildren: () => import('./feature/newdashboard/newdashboard.module').then(m => m.NewdashboardModule),
        canActivate: [REBAR_AUTH_GUARD]
    },
    {
        path: 'customdashboard',
        loadChildren: () => import('./feature/customdashboard/customdashboard.module').then(m => m.CustomdashboardModule),
        canActivate: [REBAR_AUTH_GUARD]
    },
    {
        path: 'email-reminder',
        loadChildren: () => import('./feature/email-reminder/email-reminder.module').then(m => m.EmailReminderModule),
        canActivate: [REBAR_AUTH_GUARD]
    },
    {
        path: 'mobiledashboard',
        loadChildren: () => import('./feature/mobiledashboard/mobiledashboard.module').then(m => m.MobiledashboardModule),
        canActivate: [REBAR_AUTH_GUARD]
    },
    {
        path: 'customdashboard-v2',
        loadChildren: () => import('./feature/customdashboard-v2/customdashboard-v2.module').then(m => m.CustomdashboardV2Module),
        canActivate: [REBAR_AUTH_GUARD]
    },
    {
        path: 'log-dashboard',
        loadChildren: () => import('./feature/log-dashboard/log-dashboard.module').then(m => m.LogDashboardModule),
        canActivate: [REBAR_AUTH_GUARD]
            /* {
            path: 'home',
            loadChildren: './feature/home/home.module#HomeModule',
            canActivate: [REBAR_AUTH_GUARD]
            }*/
    
    },
    {
        path: 'not-found',
        component: PageNotFoundComponent,
    },
    {
        path: 'error',
        component: ErrorComponent,
    },
    {
        path: '**',
       // component: PageNotFoundComponent,
         redirectTo: '/not-found',
         pathMatch: 'full'
    },
];
// export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
@NgModule({
   // imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule],
   })
   export class AppRoutingModule {}
   
