import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import axios from "axios";
import { RebarAuthService } from '../rebarauth/rebar.auth.service';
import { AppConfigService } from './app-config.service';
@Injectable({
  providedIn: 'root'
})
export class AdpaIntegrationService {
  configData: any = null;
  constructor(private http: HttpClient, private rebar: RebarAuthService, config: AppConfigService,private authService: MsalService) {
    this.configData = config.getConfig();
   }
  getProfileImgToken(){
    const activeAccount = this.authService.instance.getActiveAccount();
    var scope = this.configData['pI'];
    console.log(".................scope..........",scope);
    const tokenRequest = {
      scopes: ["4e9c8329-0ff3-4f7d-b357-8e4cf97e4cc2/50874.Api.ProfilePic"],
      account: activeAccount
      };
  
    return this.authService.instance.acquireTokenSilent(tokenRequest).then(res =>{
        console.log(res);
    });
  
  }
 getADPAToken(){
   const env='development'
  return this.http.post(this.rebar.configData.configURL + 'BasesheetService-service/BasesheetService?env=' + env,
  { responseType: 'text'});
 }
  
 ADPAapplyLabel(file){
  let env;  
  let clientID = this.configData['msal']['auth']['clientId'];
  if(clientID === "7d72085e-1b62-4062-911e-82d33c4dad28" ||clientID === "4378ede3-7571-42b9-b15b-dd14605a8ff0"){
     env = 'Development'
  }  
  else{
    env= 'Production'
  } 
  let param= new FormData();
  param.append('fileStream', file);
  param.append('environment', env)
  // return this.http.post(this.rebar.configData.configURL + 'BasesheetService-service/BasesheetService?applyLabel='+ env
  //       , param, { responseType: 'blob'} );  
         
  return this.http.post(this.rebar.configData.configURL + 'BasesheetService-service/BasesheetService/adpaApplyLabel',
         param, { responseType: 'blob'} );  
 }
}
