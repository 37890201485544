<nav class="navbar navbar-expand-md bg-dark navbar-dark static-top text-light" style="background: #460073 !important;">
  <div *ngIf = "pageUrl == '/monitor' ">
  <a class="navbar-brand MDtext" href="/monitor">MD Dashboard</a>
</div>
<div *ngIf = "pageUrl != '/monitor' ">
  <a class="navbar-brand MDtext" href="#">MD Dashboard</a>
</div>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav ml-auto mr-1">
      <div *ngIf = "userRole == 'Data Provider'|| userRole == 'Operations'; else elseBlock">
      </div>
      <ng-template #elseBlock> 
   
     
     <!-- if page is basesheet -->
     <li class="nav-item" *ngIf = "pageUrl == '/basesheet' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/dashboard">HOME</a>
    </li>
    <!-- <li class="nav-item" *ngIf = "pageUrl == '/basesheet' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/newdashboard">SALES DASHBOARD</a>
    </li> -->

    <!-- <li class="nav-item" *ngIf = "pageUrl == '/basesheet' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/mobiledashboard">MOBILE DASHBOARD</a>
    </li>

    <li class="nav-item" *ngIf = "pageUrl == '/basesheet' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/customdashboard">CUSTOM DASHBOARD</a>
    </li> -->

    <li class="nav-item" *ngIf = "pageUrl == '/basesheet' && userRole!='Application Monitor' && userRole!='Media Provider' && igRole =='IDC'">
      <a class="nav-link" href="/fuut">FUUT</a>
    </li>
     <!-- <li class="nav-item" *ngIf = "pageUrl == '/basesheet' && userRole!='Application Monitor' && userRole!='Media Provider' && igRole =='IDC'">
      <a class="nav-link" href="/data-upload">DATA UPLOAD</a>
    </li> -->
  
<!-- if page is dashboard -->
    <!-- <li class="nav-item" *ngIf = "pageUrl == '/dashboard' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/mobiledashboard">MOBILE DASHBOARD</a>
    </li>

    <li class="nav-item" *ngIf = "pageUrl == '/dashboard' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/customdashboard">CUSTOM DASHBOARD</a>
    </li> -->
    <!-- <li class="nav-item" *ngIf = "pageUrl == '/dashboard' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/newdashboard">SALES DASHBOARD</a>
    </li> -->
    <li class="nav-item" *ngIf = "pageUrl == '/dashboard' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/basesheet">BASESHEET</a>
    </li>
    <li class="nav-item" *ngIf = "pageUrl == '/dashboard' && userRole!='Application Monitor' && userRole!='Media Provider' && igRole =='IDC'">
      <a class="nav-link" href="/fuut">FUUT</a>
    </li>
    <!-- <li class="nav-item" *ngIf = "pageUrl == '/dashboard' && userRole!='Application Monitor' && userRole!='Media Provider' && igRole =='IDC'">
      <a class="nav-link" href="/data-upload">DATA UPLOAD</a>
    </li> -->
    <!-- if page is fuut -->
    <li class="nav-item" *ngIf = "pageUrl == '/fuut' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/dashboard">HOME</a>
    </li>
    <!-- <li class="nav-item" *ngIf = "pageUrl == '/fuut' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/newdashboard">SALES DASHBOARD</a>
    </li> -->
    <!-- <li class="nav-item" *ngIf = "pageUrl == '/fuut' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/mobiledashboard">MOBILE DASHBOARD</a>
    </li>
    <li class="nav-item" *ngIf = "pageUrl == '/fuut' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/customdashboard">CUSTOM DASHBOARD</a>
    </li> -->
    <li class="nav-item" *ngIf = "pageUrl == '/fuut' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/basesheet">BASESHEET</a>
    </li>
    <!-- <li class="nav-item" *ngIf = "pageUrl == '/fuut' && userRole!='Application Monitor' && userRole!='Media Provider' && igRole =='IDC'">
      <a class="nav-link" href="/data-upload">DATA UPLOAD</a>
    </li> -->
    
    <!-- if page is data upload -->
    <li class="nav-item" *ngIf = "pageUrl == '/data-upload' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/dashboard">HOME</a>
    </li>
    <!-- <li class="nav-item" *ngIf = "pageUrl == '/data-upload' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/newdashboard">SALES DASHBOARD</a>
    </li> -->
    <!-- <li class="nav-item" *ngIf = "pageUrl == '/data-upload' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/mobiledashboard">MOBILE DASHBOARD</a>
    </li>
    <li class="nav-item" *ngIf = "pageUrl == '/data-upload' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/customdashboard">CUSTOM DASHBOARD</a>
    </li> -->
    <li class="nav-item" *ngIf = "pageUrl == '/data-upload' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/basesheet">BASESHEET</a>
    </li>
    <li class="nav-item" *ngIf = "pageUrl == '/data-upload' && userRole!='Application Monitor' && userRole!='Media Provider' && igRole =='IDC'">
      <a class="nav-link" href="/fuut">FUUT</a>
    </li>

    <!-- if page is new dashboard -->
    <!-- <li class="nav-item" *ngIf = "pageUrl == '/newdashboard' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/dashboard">HOME</a>
    </li> -->
    
    <!-- <li class="nav-item" *ngIf = "pageUrl == '/newdashboard' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/mobiledashboard">MOBILE DASHBOARD</a>
    </li>
    <li class="nav-item" *ngIf = "pageUrl == '/newdashboard' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/customdashboard">CUSTOM DASHBOARD</a>
    </li> -->
    <!-- <li class="nav-item" *ngIf = "pageUrl == '/newdashboard' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/basesheet">BASESHEET</a>
    </li>
    <li class="nav-item" *ngIf = "pageUrl == '/newdashboard' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/fuut">FUUT</a>
    </li>
    <li class="nav-item" *ngIf = "pageUrl == '/newdashboard' && userRole!='Application Monitor' && userRole!='Media Provider'">
      <a class="nav-link" href="/data-upload">DATA UPLOAD</a>
    </li> -->
    

     <!-- if page is custom dashboard -->
    

     <!-- if page is mobile dashboard -->
    

    <!-- if page is custom dashboard v2 -->
   
    <!-- if page is Email Reminder -->
    

    <!-- profile img login/logout  -->
    </ng-template>
      <li class="nav-item">
        <img class="profile-Img" [src]="userImage" alt="" (error)="setDefaultPic()">
      </li>
      <li>
         <span [innerHTML]="userName" style="margin-right: 50px;" class="UserName"></span>
         <div class="Role">
           <span [innerHTML]="userRole" ></span>
           <img src="../../../assets/img/arrow.PNG" alt="user Role">
            </div>
      </li>
      <li class="nav-item">
        <a (click)="logout()" class="nav-link" style="text-align:left">LOGOUT</a> 
      </li>
      <li class="nav-item">
        <img (click)="openPopup()" class="info-icon" src="../../../assets/img/info.PNG" alt="">
      </li>
      <!-- <li class="nav-item">
        <img (click)="openFeedbackPopup()" data-toggle="tooltip" data-placement="bottom" title="Feedback"  style="margin-left: 7px;" class="info-icon" src="../../../assets/img/feed1.png" alt="">
      </li> -->
     </ul>
  </div>
</nav>

