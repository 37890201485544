import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { RebarAuthService } from '../../core/rebarauth/rebar.auth.service';


@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(private http: HttpClient, private rebar: RebarAuthService ) { }
  GetUserDetails(entId) {
    const param ={
      "entId" : entId,
      "isAuthenticate" : true
    }
  //  return this.http.get(this.rebar.configData.configURL +
  //  'MDDashboardService-service/MDDashboardService?entId=' + entId + '&IsAuthenticate=true', {headers: this.rebar.setHttpHeader()});
  // return this.http.get(this.rebar.configData.configURL +
  //   'MDDashboardService-service/MDDashboardService?entId=' + entId + '&IsAuthenticate=true');
  return this.http.post(this.rebar.configData.configURL +
      'MDDashboardService-service/MDDashboardService/getUserDetails', param);

}
}
