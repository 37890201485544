import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { RebarAuthService } from '../../core/rebarauth/rebar.auth.service';
import { FeedbackModel } from './feedback.model';
import { FeedbackService } from './feedback.service';

@Component({
  selector: 'rebar-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  fbCatDropdownSettings = {  };
  fbCategory = ['MD Summary', ' Profile Page', 'Comparison Page', 'MD Attrition',' Basesheet', 'FUUT Upload','Other'];
  Form: UntypedFormGroup;
  feedback: FeedbackModel;
  successfulSave = false;
  successMsg: string;

  constructor(private service: FeedbackService, private rebar: RebarAuthService ) { }

  ngOnInit() {
    this.fbCatDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'Email_id',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      clearSearchFilter: true,
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };
    this.Form = new UntypedFormGroup({
      fbCategory: new UntypedFormControl(null),
      question: new UntypedFormControl (null),
      detail: new UntypedFormControl(null),
    });
  }
  onSubmit() {
    this.successfulSave = false;
    this.feedback = new FeedbackModel(
   (this.Form.get('fbCategory').value).toString(),
   this.Form.get('question').value,
   this.Form.get('detail').value
 );
    console.log('feedback value: ' + this.feedback);
    const data = JSON.parse(JSON.stringify(this.feedback));
    // this.service.PostFeedbackData(this.msal.getAccount().userName, data).subscribe(
      this.service.PostFeedbackData(this.rebar.getUser(), data).subscribe(
  (data) => {
    this.successfulSave = true;
    this.successMsg = 'Feedback Added Successfully';

  });


  }
  onSuccessAlertClose() {
    this.successfulSave = false;
  }

}
